import { call, put } from 'redux-saga/effects'

import { getCommonArgs } from 'src/redux/sagas/selector/deviceData'

import { setSingleDeviceData } from 'src/redux/slices/deviceData'

import __socket_API_sender from 'src/redux/sagas/services/socketTools/__socket_API_sender'
import beamformersApi from 'src/redux/sagas/services/socketAPI/beamformers'

import { devWarLog } from 'src/funcs/tools'

export function* commonSignalGeneratorChange(payloads) {
  try {
    yield console.log('commonSignalGeneratorChange', payloads)
    const { sn, value } = payloads
    let { currentData, lookupID } = yield call(getCommonArgs, sn)
    const sgInvalidMin = currentData.deviceControl.common.sgFreqMin
    const sgInvalidMax = currentData.deviceControl.common.sgFreqMax

    // FE 需要維持 string, 不然小數點會無法輸入
    currentData.deviceControl.common.currentSgFreq = value
    yield put(setSingleDeviceData({ sn, data: currentData }))

    const isInvalid = value < sgInvalidMin || value > sgInvalidMax

    // 給 BE 再轉 number
    if (!isInvalid)
      yield call(
        __socket_API_sender,
        beamformersApi.CLOVERCELL_ARRAY1024_COMMON_SIGNAL_GENERATOR_FREQUENCY,
        { sn, lookupID, value: +value }
      )
  } catch (error) {
    devWarLog('[handler] commonAntennaPolarizationChange error:', error)
  }
}
