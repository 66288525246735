/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

import Beam3DControlCore from 'src/components/composite/beamformers/Beam3DControl/Core'

import {
  degreeToRadian,
  radianToDegree,
  getSphericalByCartesian,
  topViewXyzToCartesianXyz,
  leftViewXyzToCartesianXyz,
} from '../tools'

import TargetBeamControl from './TargetBeamControl'

import useBindErrorBoundary from 'src/hooks/useBindErrorBoundary'
import { risPower } from 'src/constants/beamformers'
import { getBeamIndex, getBeamName, isIncident } from 'src/funcs/device/ris'

import { cameraPosition } from 'src/constants/beamformers'
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------      Example       -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
// <Beam3DControlForRIS
//   {...{
//     sn = '',
//     deviceType = 0,
//     targetBeam = 'horizon',
//     handleTargetBeamChange = () => {},
//     thetaI = 0,
//     thetaR = 0,
//     phiI = 0,
//     phiR = 0,
//     thetaMaxI = 0,
//     thetaMaxR = 0,
//     handleDrag = () => {},
//     handleDragEnd = () => {},
//     beam3DCameraArgs = {
//       position: { x: 0, y: 0, z: 0 },
//       rotation: { x: 0, y: 0, z: 0 },
//     },
//     handleCameraChange = () => {},
//     cameraReset = false,
//     handleCameraReset = () => {},
//     deviceDirection = 'top', // top, left
//   }}
// />

const Beam3DControlForRIS = ({
  sn = '',
  deviceType = 0,
  targetBeam = 'incident',
  handleTargetBeamChange = () => {},
  thetaI = [0],
  phiI = [0],
  thetaMaxI = [0],
  thetaR = [0],
  phiR = [0],
  thetaMaxR = [0],

  handleDrag = () => {},
  handleDragEnd = () => {},
  beam3DCameraArgs = {
    position: { x: 0, y: 0, z: 0 },
    rotation: { x: 0, y: 0, z: 0 },
  },
  handleCameraChange = () => {},
  isCameraReset = false,
  handleCameraReset = () => {},
  deviceDirection = 'top', // top, left
}) => {
  const [isDragging, setIsDragging] = useState(false)

  const isDraggable = true

  const beamIRef = useRef([null])
  const beamRRef = useRef([null])

  const power = risPower[getBeamName(targetBeam)]
  const beamIndex = getBeamIndex(targetBeam)
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------     Life Cycle     -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  // beam 位置不管 top or left 都在對角線,
  // + 180 degree 來調到正確位置
  const beamOffset = 180

  const beamThetaMax = isIncident(targetBeam)
    ? thetaMaxI[beamIndex]
    : thetaMaxR[beamIndex]
  const beamTheta = _targetBeam =>
    isIncident(_targetBeam)
      ? thetaI[getBeamIndex(_targetBeam)]
      : thetaR[getBeamIndex(_targetBeam)]

  const beamPhi = _targetBeam =>
    isIncident(_targetBeam)
      ? phiI[getBeamIndex(_targetBeam)]
      : phiR[getBeamIndex(_targetBeam)]

  let ballTheta = isIncident(targetBeam) ? thetaI[beamIndex] : thetaR[beamIndex]
  let ballPhi = isIncident(targetBeam) ? phiI[beamIndex] : phiR[beamIndex]

  // 每次更新 theta & phi 更新 beam
  // (disk & ball 元件內部更新)
  useEffect(() => {
    if (beamIRef.current) {
      thetaI.forEach((e, i) => {
        beamIRef?.current?.[i]?.rotation.set(
          0,
          degreeToRadian(+phiI[i] + beamOffset),
          degreeToRadian(+e)
        )
      })
    }

    if (beamRRef.current) {
      thetaR.forEach((e, i) => {
        beamRRef?.current?.[i]?.rotation.set(
          0,
          degreeToRadian(+phiR[i] + beamOffset),
          degreeToRadian(+e)
        )
      })
    }
  }, [
    phiI,
    phiR,
    thetaI,
    thetaR,
    beamIRef.current[0],
    beamRRef.current[0],
    targetBeam,
  ])

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        Func        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  // 限制theta 不超過最大 theta
  const thetaMaxLimit = theta =>
    Math.max(-beamThetaMax, Math.min(beamThetaMax, theta))

  // phi 校正，因為滑鼠繞一圈會繞到 viewX viewZ 的
  // 正數區塊 與 負數區塊  (phi 的結果會變成 -180 ~ 180)
  // 這邊透過補正把他補成完整的 "正數圓"，作為 phi 使用
  const phiCalibration = degreePhi =>
    deviceDirection === 'top'
      ? (degreePhi + 180 + 90) % 360 // + 180 = 把 max 補滿 360 / + 90 = 讓球的位置跟滑鼠一致 / % 360 = 永遠都不會超過 360
      : (degreePhi - 180) * -1 // - 180 = 把 max 補滿 -360 / left 是反著轉 直接 * -1

  // ==============================
  // [getCartesianXyzByViewXyz]
  // ==============================
  // view XYZ 轉 笛卡爾 XYZ, deviceDirection 轉向不同，相對應 XYZ 也不同
  const getCartesianXyzByViewXyz = (deviceDirection, [vx, vy, vz]) => {
    if (deviceDirection === 'top') return topViewXyzToCartesianXyz(vx, vy, vz)
    if (deviceDirection === 'left') return leftViewXyzToCartesianXyz(vx, vy, vz)
  }

  // ==============================
  // [mousePointProcess]
  // ==============================
  // power 軸若是低於零，抓的點會跑到對向去
  const preventCatchToBackSide = BackSide => (BackSide < 0 ? 0 : BackSide)

  const verticalDisabled = 0

  // mouse event 預處理 (限制 power 不會小於 0)
  //! 限制 v1 只能水平移動
  const mousePointProcess = (deviceDirection, [mouseX, mouseY, mouseZ]) => {
    if (deviceDirection === 'top')
      return [
        mouseX, // horizontal
        preventCatchToBackSide(mouseY), // depth
        verticalDisabled, //! v1 ris 只支援水平 vertical
        // mouseZ, //! v2 後 ris 後支援全角度 vertical
      ]

    if (deviceDirection === 'left')
      return [
        preventCatchToBackSide(mouseX), // depth
        verticalDisabled, //! v1 ris 只支援水平 vertical
        // mouseY, //! v2 後 ris 支援全角度 vertical
        mouseZ, // horizontal
      ]
  }

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       Event        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* [handleOnDrag]
  const handleOnDrag = useBindErrorBoundary((target, mouse3D) => {
    // target = theta or phi , 判斷是控制 theta ball or phi ball

    // 抓到滑鼠所在的 viewXyz (radian)
    const viewXyz = mousePointProcess(deviceDirection, mouse3D)

    // 轉成 笛卡爾的 xyz (radian)
    const cXyz = getCartesianXyzByViewXyz(deviceDirection, viewXyz)

    // 轉成 球座標 theta & phi (radian)
    const [, radianTheta, radianPhi] = getSphericalByCartesian(cXyz)

    const oldTheta = ballTheta
    const degreeTheta = radianToDegree(radianTheta)
    const degreePhi = radianToDegree(radianPhi)

    // 限制最大 theta
    const newTheta = thetaMaxLimit(degreeTheta)

    // 操作的是 theta ball 才能同時改變 theta + phi
    // 操作的是 phi ball 只能改變 phi 所以吃舊的 theta
    const resultTheta = target === 'theta' ? newTheta : oldTheta
    const resultPhi = phiCalibration(degreePhi)

    // 更新 theta & phi
    // 由 useEffect 的 theta & phi 更新改變 disk, dotted - line 的 position
    // 去掉多餘的小數點 (degree)
    handleDrag({
      resultPhi: Math.floor(resultPhi),
      resultTheta: Math.floor(resultTheta),
      targetBeam,
    })

    return null
  })

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       Props       ------------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const dragProps = isDraggable
    ? {
        onDragStart: () => setIsDragging(true),
        onDrag: (name, mouse3D) => handleOnDrag(name, mouse3D),
        onDragEnd: event => {
          handleDragEnd(event)
          setIsDragging(false)
        },
      }
    : {
        onDragStart: () => void null,
        onDrag: (name, mouse3D) => void null,
        onDragEnd: () => void null,
      }

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------

  return (
    <div className='relative w-full h-full select-none '>
      <TargetBeamControl
        {...{ targetBeam, thetaI, thetaR, handleTargetBeamChange }}
      />

      <Beam3DControlCore
        {...{
          sn, // str
          deviceType, // str
          beam3DCameraArgs, // { position: { x: 0, y: 0, z: 0 }, rotation: { x: 0, y: 0, z: 0 }, }
          defaultCameraPosition: cameraPosition.far,
          deviceDirection, // 'top', 'left'
          isCameraReset, // bool
          isDragging, // bool
          isEnableZoom: true,
          handleCameraChange, // func
          handleCameraReset, // func
        }}>
        {thetaI.map((e, i, a) => (
          <Beam3DControlCore.ArrowBeam
            key={`beamI-${i}`}
            {...{
              ref: el => (beamIRef.current[i] = el),
              lookAt: 'inside',
              text: `Incident ${a.length >= 2 ? i + 1 : ''}`,
              theta: beamTheta(`incident_${i}`),
              phi: beamPhi(`incident_${i}`),
              power,
              isActive: targetBeam === `incident_${i}`,
              dragProps,
              onClick: () => handleTargetBeamChange(`incident_${i}`),
            }}
          />
        ))}

        {thetaR.map((e, i, a) => (
          <Beam3DControlCore.ArrowBeam
            key={`beamR-${i}`}
            {...{
              ref: el => (beamRRef.current[i] = el),
              lookAt: 'outside',
              text: `Reflector ${a.length >= 2 ? i + 1 : ''}`,
              theta: beamTheta(`reflector_${i}`),
              phi: beamPhi(`reflector_${i}`),
              power,
              isActive: targetBeam === `reflector_${i}`,
              dragProps,
              onClick: () => handleTargetBeamChange(`reflector_${i}`),
            }}
          />
        ))}

        <Beam3DControlCore.BallAndDisk
          {...{
            power,
            theta: ballTheta,
            phi: ballPhi,
            dragProps,
          }}
        />
      </Beam3DControlCore>
    </div>
  )
}

export default Beam3DControlForRIS

Beam3DControlForRIS.propTypes = {
  sn: PropTypes.string,
  deviceType: PropTypes.number,
  dragHVMode: PropTypes.oneOf(['horizon', 'vertical']),
  handleTargetBeamChange: PropTypes.func,
  thetaI: PropTypes.array,
  thetaR: PropTypes.array,
  phiI: PropTypes.array,
  phiR: PropTypes.array,
  thetaMaxI: PropTypes.array,
  thetaMaxR: PropTypes.array,
  handleDrag: PropTypes.func,
  handleDragEnd: PropTypes.func,
  handleCameraChange: PropTypes.func,
  cameraReset: PropTypes.bool,
  handleCameraReset: PropTypes.func,
  deviceDirection: PropTypes.oneOf(['top', 'left']),
}
